<template>
  <div id="pc">
    <HomeHeader />
    <div class="banner">
      <div class="banner-top">
        <img src="~@/assets/images/pc/home/home-banner.png" alt="" />
        <!-- <img src="~@/assets/images/pc/home/time.png" alt="" class="time"/> -->
      </div>

      <div class="promo-cards">
        <img class="active" src="@/assets/images/pc/home/huodong.png" alt="" />
        <div class="bg-wrapper">
          <router-link
            class="router-link"
            :to="item.to"
            v-for="item in items"
            :key="item.id"
          >
            <!-- :class="{ first: item.id == 1 } -->
            <div>
              <img class="bg" :src="item.src" />
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeHeader from "@/components/pc/HomeHeader";

import elementHeight from "@/mixins/pc/elementHeight";

export default {
  name: "HomePC",
  mixins: [elementHeight],
  data() {
    return {
      items: [
        {
          id: 1,
          src: require("@/assets/images/pc/home/promo-card1.png"),
          to: "/commission",
        },
        {
          id: 2,
          src: require("@/assets/images/pc/home/promo-card2.png"),
          to: "/newSignup",
        },
        {
          id: 3,
          src: require("@/assets/images/pc/home/promo-card3.png"),
          to: "/betRebate",
        },
        {
          id: 4,
          src: require("@/assets/images/pc/home/promo-card4.png"),
          to: "/recommendation",
        },
        {
          id: 5,
          src: require("@/assets/images/pc/home/promo-card5.png"),
          to: "/doubleGift",
        },
        {
          id: 6,
          src: "",
          to: "",
        },
        // {
        //   id: 7,
        //   src: require("@/assets/images/pc/home/promo-card7.png"),
        //   to: "/overallRanking",
        // },
        // {
        //   id: 8,
        //   src: require("@/assets/images/pc/home/promo-card8.png"),
        //   to: "/goodGift",
        // },
      ],
    };
  },
  components: {
    HomeHeader,
  },
};
</script>

<style lang="less" scoped>
#pc {
  // width: 1920px;
  font-size: 16px;
  box-sizing: border-box;
  background: url("~@/assets/images/pc/home/bg.png") no-repeat;
  background-size: 100% 100%;
  .first {
    width: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .banner {
    // margin-top: 20px;

    width: 100%;
    overflow: hidden;

    .banner-top {
      width: 62%;
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 20px;
      img {
        width: 100%;
      }
      .time {
        width: 60%;
      }
    }

    .banner-bottom {
      height: 41px;
      display: block;
      margin: 0 auto;
      margin-top: 4px;
    }
  }
  .promo-cards {
    padding-bottom: 64px;
    background: #fff;
    border-radius: 20px;
    display: flex;
    width: 6.25rem;
    margin: 0 auto;
    // margin-top: px;
    justify-content: space-evenly;
    align-content: space-evenly;
    position: relative;

    .active {
      position: absolute;
      width: 2rem;
      top: 40px;
    }
    .bg-wrapper {
      width: 100%;
      margin: 0 auto;
      margin-top: 100px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;
      align-content: space-evenly;
      .router-link {
        position: relative;

        .bg {
          width: 3rem;
          margin-bottom: 12px;
        }
      }
    }
  }
}
</style>
