import Vue from "vue";
import VueRouter from "vue-router";
import HomePC from "../views/pc/home";
import device from "current-device";

Vue.use(VueRouter);
const pcRoutes = [
  {
    path: "/",
    name: "HomePC",
    component: HomePC,
  },
  {
    path: "/commission",
    name: "Commission",
    component: () =>
      import(/* webpackChunkName: "commission" */ "../views/pc/commission"),
  },
  {
    path: "/newSignup",
    name: "NewSignup",
    component: () =>
      import(/* webpackChunkName: "newSignup" */ "../views/pc/new-signup"),
  },
  {
    path: "/betRebate",
    name: "BetRebate",
    component: () =>
      import(/* webpackChunkName: "betRebate" */ "../views/pc/bet-rebate"),
  },
  {
    path: "/recommendation",
    name: "Recommendation",
    component: () =>
      import(
        /* webpackChunkName: "recommendation" */ "../views/pc/recommendation"
      ),
  },

  {
    path: "/doubleGift",
    name: "DoubleGift",
    component: () =>
      import(/* webpackChunkName: "doubleGift" */ "../views/pc/double-gift"),
  },
  // {
  //   path: "/weeklyRanking",
  //   name: "WeeklyRanking",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "WeeklyRanking" */ "../views/pc/weekly-ranking"
  //     ),
  // },
  // {
  //   path: "/overallRanking",
  //   name: "OverallRanking",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "OverallRanking" */ "../views/pc/overall-ranking"
  //     ),
  // },
  // {
  //   path: "/goodGift",
  //   name: "GoodGift",
  //   component: () =>
  //     import(/* webpackChunkName: "GoodGift" */ "../views/pc/good-gift"),
  // },
];

const h5Routes = [
  {
    path: "/m",
    name: "HomeH5",
    component: () => import(/* webpackChunkName: "m" */ "../views/h5/home"),
  },
  {
    path: "/mCommission",
    name: "MCommission",
    component: () =>
      import(/* webpackChunkName: "mCommission" */ "../views/h5/commission"),
    meta: { title: "白露迎金秋 合营专享" },
  },
  {
    path: "/mNewSignup", 
    name: "MNewSignup", 
    component: () =>
      import(/* webpackChunkName: "mNewSignup" */ "../views/h5/new-signup"),
    meta: { title: "花好月圆 新增享毫礼" },
  },
  {
    path: "/mBetRebate",
    name: "MBetRebate",
    component: () =>
      import(/* webpackChunkName: "mBetRebate" */ "../views/h5/bet-rebate"),
    meta: { title: "四海一明月 千山共白云" },
  },
  {
    path: "/mRecommendation",
    name: "MRecommendation",
    component: () =>
      import(
        /* webpackChunkName: "mRecommendation" */ "../views/h5/recommendation"
      ),
    meta: { title: "天涯共此时 投注享好礼" },
  },
  {
    path: "/mOverallRanking",
    name: "mOverallRanking",
    component: () =>
      import(
        /* webpackChunkName: "mOverallRanking" */ "../views/h5/overall-ranking"
      ),
    meta: { title: "天涯共此时 投注享好礼" },  
  },
  {
    path: "/mWeeklyRanking",
    name: "mWeeklyRanking",
    meta: { title: "推荐好友 返利无限" },
    component: () =>
      import(
        /* webpackChunkName: "mWeeklyRanking" */ "../views/h5/weekly-ranking"
      ),
  },
  //   {
  //   path: "/mGoodGift",
  //   name: "mGoodGift",
  //   meta: { title: "欧洲杯投注有好礼" },
  //   component: () =>
  //     import(/* webpackChunkName: "mGoodGift" */ "../views/h5/good-gift"),
  // },
  // double-gift内页切换tab
  // {
  //   path: "/mDoubleGift", 
  //   name: "MDoubleGift",
  //   meta: { title: "天涯共此时 投注享好礼" },
  //   component: () =>
  //     import(/* webpackChunkName: "mDoubleGift" */ "../views/h5/double-gift"),
  // },
  // {
  //   path: "/mOverallRanking",
  //   name: "mOverallRanking",
  //   meta: { title: "欧洲杯嘉年华 总排行奖励" },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "mOverallRanking" */ "../views/h5/overall-ranking"
  //     ),
  // },
  // {
  //   path: "/mGoodGift",
  //   name: "mGoodGift",
  //   meta: { title: "欧洲杯投注有好礼" },
  //   component: () =>
  //     import(/* webpackChunkName: "mGoodGift" */ "../views/h5/good-gift"),
  // },
];

const routes = [
  // pc
  ...pcRoutes,
  // h5
  ...h5Routes,
  {
    path: "*",
    redirect: "/",
  },
];

// router刷新报错处理
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  viewByDevice(to.path, next);
});

const pcPaths = pcRoutes.map((el) => el.path);
const h5Paths = h5Routes.map((el) => el.path);

function viewByDevice(path, next) {
  if (device.mobile()) {
    const pcPath = pcPaths.find((el) => el === path);
    pcPath ? next(pcToH5(path)) : next();
  } else {
    const h5Path = h5Paths.find((el) => el === path);
    h5Path ? next(h5ToPc(path)) : next();
  }
}

function pcToH5(path) {
  const left = path[0];
  let right = path.slice(1);
  right = (right[0] || "").toUpperCase() + right.slice(1);
  const resultPath = `${left}m${right}`;
  // console.log("pcToH5 resultPath", resultPath, location.search)

  return resultPath + location.search;
}

function h5ToPc(path) {
  const left = path[0];
  let right = path.slice(2);
  right = (right[0] || "").toLowerCase() + right.slice(1);
  const resultPath = `${left}${right}`;
  // console.log("h5ToPc resultPath", resultPath, location.search)
  return resultPath + location.search;
}
router.afterEach((to, from) => {
  // 导航完成后滚动到顶部
  window.scrollTo(0, 0);
});

export default router;
