import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

// 入口样式文件
import "./assets/css/index.css"

// h5自适应
import "amfe-flexible/index"

import "@/plugins/loading"

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app")
