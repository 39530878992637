<template>
  <div id="app">
    <router-view class="router-view" />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="less">
#app {
  font-size: 16px;
}

.router-view {
  font-size: 16px;
}
</style>
