const elementHeight = {
  data() {
    return {
      headerHeight: "0.3333rem", // 头部高度
      fixedFooterHeight: "0.6042rem",
      paddingBottom: "0.7rem"
    }
  }
}

export default elementHeight
